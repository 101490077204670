import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ReactMarkdownWithHtml from "react-markdown/with-html"
import SEO from "../components/seo"
import style from "./press.module.scss"

import HeroImage from "../components/HeroImage"

const Page = ({ data }) => {
  const writing = {}
  data.allStrapiWriting.edges.forEach(element => {
    writing[element.node.key] = element.node.writing
  })
  const page = data.allStrapiPage.edges[0].node
  let headerImagePortrait = null
  let headerImageLandscape = null
  let headerBackground = "/images/header.speaker.svg"
  let headerWriting = ""
  switch (page.slug) {
    case "call-for-speaker-ixdtw2021":
      headerImagePortrait = "/images/hero.call-for-speaker.portrait.svg"
      headerImageLandscape = "/images/hero.call-for-speaker.landscape.svg"
      headerBackground = null
      headerWriting = writing.call_for_speaker_header || headerWriting
      break
    case "venue-ixdtw2021":
      headerImagePortrait = "/images/hero.venue.portrait.svg"
      headerImageLandscape = "/images/hero.venue.landscape.svg"
      headerBackground = null
      headerWriting = writing.venue_header || headerWriting
      break
    case "about-ixdtw2021":
      headerImagePortrait = "/images/hero.about.portrait.svg"
      headerImageLandscape = "/images/hero.about.landscape.svg"
      headerBackground = null
      headerWriting = writing.about_ixdtw2021_header || headerWriting
      break
    default:
      break
  }
  let content = page.content
  try {
    content = page.content.replaceAll(
      /https:\/\/storage.googleapis.com\/ixdadb.appspot.com\/([\w]+)\/([\w]+)./g,
      `https://storage.googleapis.com/ixdadb.appspot.com/large_$1/large_$2.`
    )
  } catch (e) {}

  return (
    <Layout
      hero={headerImagePortrait && headerImageLandscape}
      headerBackground={headerBackground}
    >
      <SEO
        title={page.title}
        description={
          page.description && page.description.replace(/(?:\r\n|\r|\n)/g, " ")
        }
        image={page.og_image && page.og_image.childImageSharp.fixed.src}
      />
      {headerImagePortrait && headerImageLandscape && (
        <HeroImage
          writing={headerWriting}
          portrait={headerImagePortrait}
          landscape={headerImageLandscape}
        />
      )}
      <div className={style.fullContent}>
        <h1 className={style.fullTitle}>{page.title}</h1>
        <ReactMarkdownWithHtml
          allowDangerousHtml={true}
          children={content}
        ></ReactMarkdownWithHtml>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allStrapiWriting(filter: { site: { id: { eq: 2 } } }) {
      edges {
        node {
          key
          writing
          site {
            id
          }
        }
      }
    }
    allStrapiPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          slug
          title
          description
          content
          og_image {
            childImageSharp {
              fixed {
                src
              }
            }
          }
          created_at
        }
      }
    }
  }
`
export default Page
